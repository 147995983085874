import styled, { CSSProperties } from 'styled-components';
import { COLOR_MAP } from '../../componentUI/colorsMap';

const HeaderRedesignStyled = styled.div`
  width: 100%;

  padding: 8px 16px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;

  background-color: ${COLOR_MAP.background.darkGrey};
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const stylesForLinkRouter: CSSProperties = {
  maxHeight: '28px',
  padding: '5px 10px 5px 5px',
  display: 'flex',
  gap: '5px',
  alignItems: 'center',
  borderRadius: '16px',
  border: `1px solid ${COLOR_MAP.accent.lightGrey}`,
};

export { ButtonsWrapper, HeaderRedesignStyled, IconWrapper, stylesForLinkRouter };
