/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useAccountInfoQuery } from '../../api/auth/hooks';

import { useIsDesktop } from '../../hooks';
import { useOnboardingModal } from '../../hooks/useOnboardingModal';

import { ColumnMenu } from '../../modules/ColumnMenu/containers';
import { HeaderRedesign } from '../../modules/HeaderRedisign/HeaderRedesign';

import { OnboardingSlider } from '../OnboardingSlider';

import { WelcomeBannerNewUser } from '../Banners';
import { BreadCrumbs } from '../BreadCrumbs';
import { ControlsBlock } from '../ControlsBlock';

import {
  BaseContainer,
  BreadCrumbsWrapper,
  ContentContainer,
  GlobalStyleFromPage,
  MainContainer,
  Overlay,
} from './styled';

/** Содержит боковое меню и шапку c нотификациями и логаутом
 * на >900px уходит боковое меню и отображается шапка  т.к. таблицы корректно не помещаются по ширине
 * на >600px переходит в мобильную версию
 */

const OVERLAYING_MODAL_KEY = 'OverlayingModalHidden';

export const BaseLayout = ({
  children,
  isSummaryBlock,
  isNotDvh,
}: {
  children: JSX.Element;
  isSummaryBlock?: boolean;
  isNotDvh?: boolean;
}) => {
  const { pathname } = useLocation();
  const isDesktop = useIsDesktop();
  const [OverlayingModal, toggleOverlayingModal] = useOnboardingModal();

  const [showColumnMobileMenu, setShowColumnMobileMenu] = useState(false);

  const { data: AccountData } = useAccountInfoQuery(null, true);

  // это новый пользователь у него ещё нет заказов
  const isNewAccount = !AccountData?.hasPortalOrder && !AccountData?.hasShopifyOrder;

  // это страница "Дашборд"
  const isDashboard = pathname.includes('dashboard');

  const OverlayingModalHasBeenViewed = localStorage.getItem(OVERLAYING_MODAL_KEY);

  useEffect(() => {
    if (isNewAccount && !OverlayingModalHasBeenViewed) {
      toggleOverlayingModal();
      localStorage.setItem(OVERLAYING_MODAL_KEY, 'true');
    }
  }, [isNewAccount]);

  return (
    <BaseContainer>
      {isDesktop && <ColumnMenu />}

      {!isDesktop && (
        <HeaderRedesign setShowColumnMobileMenu={setShowColumnMobileMenu} showColumnMobileMenu={showColumnMobileMenu} />
      )}

      <MainContainer>
        {isNewAccount && isDashboard && <WelcomeBannerNewUser />}

        {!isDesktop && !isSummaryBlock && (
          <BreadCrumbsWrapper isSummaryBlock={isSummaryBlock}>
            <BreadCrumbs />
          </BreadCrumbsWrapper>
        )}

        {isDesktop && !isSummaryBlock && <ControlsBlock />}

        <ContentContainer isSummaryBlock={isSummaryBlock} isNotDvh={isNotDvh}>
          {children}
        </ContentContainer>
      </MainContainer>

      <Overlay
        isColumnMenuOpen={showColumnMobileMenu}
        onClick={(event) => {
          if (event.target === event.currentTarget) {
            setShowColumnMobileMenu(false);
          }
        }}
      >
        <ColumnMenu isSidebar closeHandler={() => setShowColumnMobileMenu(false)} />
      </Overlay>

      {showColumnMobileMenu && <GlobalStyleFromPage />}

      <OverlayingModal onClose={toggleOverlayingModal}>
        <OnboardingSlider onClose={toggleOverlayingModal} />
      </OverlayingModal>
    </BaseContainer>
  );
};
