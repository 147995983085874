import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import styled from 'styled-components';
import { COLOR_MAP } from '../../colorsMap';
import { SIZE_STYLE, TEXT_SIZE, TEXT_WEIGHT } from '../Text/constant';

type CustomStyleProps = {
  size?: keyof typeof TEXT_SIZE;
  colorText?: string;
  weight?: typeof TEXT_WEIGHT[keyof typeof TEXT_WEIGHT];
  isUnderlined?: boolean;
};

type PropsLink = LinkProps & CustomStyleProps;

// Utilizing shouldForwardProp to filter out non-DOM attributes
const StyledLink = styled(Link).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['size', 'colorText', 'weight', 'isUnderlined'].includes(prop) && defaultValidatorFn(prop),
})<CustomStyleProps>`
  font-family: 'Open Sans', sans-serif;
  color: ${({ colorText }) => colorText || COLOR_MAP.text.brandColor};
  ${({ size }) => size && SIZE_STYLE[size]};
  ${({ weight }) => weight && `font-weight: ${weight}`};
  text-decoration: ${({ isUnderlined }) => (isUnderlined ? 'underline' : 'none')};
  cursor: pointer;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      opacity: 0.8;
    }
  }

  @media (hover: none) and (pointer: coarse) {
    &:active {
      opacity: 0.8;
    }
  }
`;

export const LinkRouter: React.FC<PropsLink> = ({
  to,
  size = TEXT_SIZE.regular,
  colorText = COLOR_MAP.text.brandColor,
  weight = TEXT_WEIGHT.normal,
  isUnderlined = false,
  children,
  ...otherProps
}) => {
  return (
    <StyledLink to={to} size={size} colorText={colorText} weight={weight} isUnderlined={isUnderlined} {...otherProps}>
      {children}
    </StyledLink>
  );
};
